var categorynieuws = document.getElementById("categoriesnieuws");
if(categorynieuws !== null){
	document.getElementById("categoriesnieuws").addEventListener("change", function(){
		var value = this.value
		window.location.href=document.getElementById('categoriesnieuws').value;
		console.log(value);
	});
}

var videoplayer = document.getElementById('videoplayer-container');
if(videoplayer !== null){
	var player = videoplayer.dataset.player;
	if(player !== ""){
		console.log(player);
		if(player.includes("youtube")){
			// 2. This code loads the IFrame Player API code asynchronously.
			var tag = document.createElement('script');
			tag.src = "https://www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}
		else if(player.includes("vimeo")){
			// 2. This code loads the IFrame Player API code asynchronously.
			var tag = document.createElement('script');
			tag.src = "https://player.vimeo.com/api/player.js";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}
	}

}

var videoreveal = document.getElementById('videohead');
if(videoreveal !== null){
	var player = videoreveal.querySelector('iframe').src;
	if(player !== ""){
		console.log(player);
		if(player.includes("youtube")){
			// 2. This code loads the IFrame Player API code asynchronously.
			var tag = document.createElement('script');
			tag.src = "https://www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}
		else if(player.includes("vimeo")){
			// 2. This code loads the IFrame Player API code asynchronously.
			var tag = document.createElement('script');
			tag.src = "https://player.vimeo.com/api/player.js";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}
	}

}

$(function () {


    $('.banner_nieuws').on('click', function(e){
		var href = $(this).find('.banner_nieuws_leesmeer').attr('href');
		window.location.href=href
	});
    $('.mobile-icon-box').on('click', function(e){
		$('#testing123').fadeIn();
		$('.mobile-icon-box').hide();
		$('.categoriebalknieuws').hide();
	});
    $('#testing123 .close-button').on('click', function(e){
		$('#testing123').fadeOut(400,function(){
			$('.mobile-icon-box').show();
			$('.categoriebalknieuws').show();
		});
		
	});
	$('.box1, .watchvideo, .vid-btn').on('click',function(e){
		var seconds = this.dataset.seconds;
		var locationId = this.dataset.locid;
		console.log('location'+locationId);
		var vidlocation = this.parentNode.parentNode.id;
		console.log('vidlocation'+vidlocation);

		if(!vidlocation){
			vidlocation = "videotext-block__video__container";
		}
		if(locationId !== undefined){
			vidlocation = locationId;
		}
		if(seconds === undefined || seconds === null){
			seconds = 0;

		}else{
			seconds = parseInt(seconds);
			console.log(seconds);
		}
		console.log(seconds+' click');
		initializeVideoPlayer(seconds, vidlocation);
	});


});

$('.inspiratie-arrow-back').on('click', function(e){
	if($(this)[0].hasAttribute("href")){
	}else{
		$(this)[0].href = '/inspiratie'
	}
});

var webinarPlayer;
 // Create a new Vimeo player instance and set the current time to 20 seconds
 function setPlayerTime(seconds, service, videoid) {
	console.log(seconds+' setPlayerTime '+service+ ' '+ videoid);
	if(seconds > 0){
		if(service == "vimeo"){
			if(webinarPlayer){
				webinarPlayer.setCurrentTime(seconds);
			}else{
				webinarPlayer = new Vimeo.Player('webinar-player'+videoid, {
					id: videoid
				});
				webinarPlayer.ready().then(function() {
					webinarPlayer.setCurrentTime(seconds);
					/*possible error handling
					.then(function(seconds) {
						// `seconds` indicates the actual time that the player seeks to
						}).catch(function(error) {
							switch (error.name) {
								case 'RangeError':
									// The time is less than 0 or greater than the video's duration
									console.log('error');
									break;
								default:
									// Some other error occurred
									break;
							}
						});*/
				});
			}
		}else if(service == "youtube"){
			if(webinarPlayer){
				webinarPlayer.seekTo(seconds);
			}else{
				webinarPlayer = new YT.Player('webinar-player'+videoid, {
					videoId: videoid,
					events: {
					'onReady': function(event) {
						event.target.seekTo(seconds);
					}
					}
				});
			}
		}
	}
  }


  function stopPlayer(service, videoid){
	if(service == "vimeo"){
		if(webinarPlayer){
			webinarPlayer.pause();
		}else{
			webinarPlayer = new Vimeo.Player('webinar-player'+videoid, {
				id: videoid
			});
			webinarPlayer.ready().then(function() {

				webinarPlayer.pause();
				/*possible error handling
				.then(function(seconds) {
					// `seconds` indicates the actual time that the player seeks to
					}).catch(function(error) {
						switch (error.name) {
							case 'RangeError':
								// The time is less than 0 or greater than the video's duration
								console.log('error');
								break;
							default:
								// Some other error occurred
								break;
						}
					});*/
			});
		}
	}else if(service == "youtube"){
		if(webinarPlayer){
			webinarPlayer.pauseVideo();
		}else{
			webinarPlayer = new YT.Player('webinar-player'+videoid, {
				videoId: videoid,
				events: {
				'onReady': function(event) {
					event.target.pauseVideo();
				}
				}
			});
		}
	}

  }
function initializeVideoPlayer(seconds = 0, vidlocation){
	console.log(seconds+' initializeVideoPlayer');
	console.log(vidlocation);
	if(vidlocation == "videotext-block__video__container"){
		var videoContainer = document.getElementById("videoplayer-container");
		var videoLocationID = 'videoplayer-container';
		var videochildcontainer = "videotext-block__video__container";
	}else{
		var videoContainer = document.getElementById(vidlocation);
		var videochildcontainer = videoContainer.childNodes;
		var videoLocationID = vidlocation;
		var videochildcontainer = videochildcontainer[1].className;
	}
	console.log(videochildcontainer);
	var player = videoContainer.dataset.player;
	var src = videoContainer.dataset.src;
	console.log(player);
	var videoId = '';
	if(player == "vimeo"){
		var regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
		var match = src.match(regEx);
		if (match && match.length == 7) {
			videoId = match[6];
		}
		else {
			//Handle error
			console.log('Geen video ID gevonden');
		}
	}else if(player == "youtube"){
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
		var match = src.match(regExp);
		if (match && match[7].length==11) {
			videoId = match[7];
		}else{
			//Handle error
			console.log('Geen video ID gevonden');
		}
	}

	console.log(videoId);
	if(document.getElementById('webinar-player'+videoId) !== null){
		console.log('initialized');
		//The player has already been initialized.
		//Just setPlayerTime
		setPlayerTime(seconds, player, videoId);
	}else{
		if(player == "selfhosted"){
			var videoElement = document.createElement('video');
			videoElement.id = 'webinar-player'+videoId;
			videoElement.setAttribute('loading','lazy');
			videoElement.setAttribute('controls','');
			var sourceElement = document.createElement('source');
			sourceElement.src = src;
			sourceElement.setAttribute('type','');
			videoElement.appendChild(sourceElement);
			videoContainer.appendChild(videoElement);
			videoContainer.classList.add('videoplayer-initialized');
			var videoicon = document.querySelector('#'+videoLocationID+' .'+videochildcontainer)
			videoicon.style.display ='none';
			var height = videoContainer.clientHeight;
			console.log('height'+height);
			videoElement.style.height = height+'px';
			videoElement.style.width = '100%';
			videoElement.style.borderRadius  = '8px';
		}else{
			// Wait for the iframe to be created and loaded before initializing the Vimeo player
			var iframe = document.createElement('iframe');
			iframe.id = 'webinar-player'+videoId;
			if(src.indexOf('autoplay=1') !== -1){
				iframe.src = src;
			}else{
				if(src.indexOf('?') !== -1){
					iframe.src = src+'&autoplay=1';
				}else{
					iframe.src = src+'?autoplay=1';
				}
			}
			iframe.setAttribute('frameborder','0');
			iframe.setAttribute('allow','accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
			iframe.setAttribute('allowfullscreen','');
			videoContainer.appendChild(iframe);
			iframe.addEventListener('load', setPlayerTime(seconds, player,videoId));
			videoContainer.classList.add('videoplayer-initialized');
			var videoicon = document.querySelector('#'+videoLocationID+' .'+videochildcontainer);
			console.log('foundit'+videochildcontainer);
			videoicon.style.display ='none';
			var height = videoContainer.clientHeight;
			console.log('height'+height);
			iframe.style.height = height+'px';
			iframe.style.width = '100%';
			iframe.style.borderRadius  = '8px';
		}
	}
	
}

document.getElementById('rotateRight').addEventListener('click', function () {
	const carousel = document.querySelector('.typenieuws');
	const scrollAmount = 100; // Adjust scroll distance as needed
	carousel.scrollBy({
		left: scrollAmount,
		behavior: 'smooth'
	});
});